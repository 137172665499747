import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

// import 'http://cdn.leafletjs.com/leaflet-0.7.5/leaflet.css';
// var VueScrollTo = require('vue-scrollto');

// Vue.use(VueScrollTo,{
//   offset: -50
// })
Vue.config.productionTip = false


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
