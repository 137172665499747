<template>
  <v-container class="responsive d-flex  justify-center">
    <v-card flat class="rounded-lg chip-container pb-0 d-flex flex-wrap justify-center " color="white">
      <v-card flat dark class="rounded-lg chip my-4 mx-2" v-for="(color, idx) in colors" :key="idx" :color="color">
        <p>
          <v-icon x-large @click="deleteMe(idx)" :color="color + ' lighten-2'">mdi-delete-circle</v-icon>
        </p>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'TheoResponsive',
    methods: {
      deleteMe(idx) {
        this.colors.splice(idx, 1)
      }
    },
    data() {
      return {
        colors: ['blue', 'light-blue', 'green','light-green', 'orange', 'deep-orange']
      }
    }
  }
</script>

<style lang="scss" scoped>
.chip {
  transition: all 3s ease;
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chip-container {
  animation: 3s resize ease-in-out infinite;
  border: 16px solid #f2f2f2 !important;
  font-size: 64px;
    width: 5em;
    height: 4em;
}
.responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 400px;
}
@keyframes resize {
  to {
    width: 5em;
    height: 4em;
  }
  25% {
    width: 4em;
    height: 5em;
  }
  50% {
    width: 4em;
    height: 5em;
  }
  75% {
    width: 5em;
    height: 4em;
  }

}
</style>