

<template>
  <div class="container">
    <span class="typer">
      <span class="typed-text"><span v-html="typeValue"></span></span>
      <span class="blinking-cursor">|</span>
      <span class="cursor" :class="{ typing: typeStatus }">&nbsp;</span>
    </span>
  </div>
</template>

<script>
export default {
  name: "TheoTyper",
  data: () => {
    return {
      typeValue: "",
      typeStatus: false,
      // displayTextArray: ["YouTuber", "Developer", "Blogger", "Designer", "Freelancer"],
      typingSpeed: 50,
      erasingSpeed: 100,
      newTextDelay: 1000,
      displayTextArrayIndex: 0,
      charIndex: 0,
      timer: null,
    };
  },
  props: ["displayTextArray" ],
  created() {
    this.timer = setTimeout(this.typeText, this.newTextDelay + 200);
  },
  methods: {
    typeText() {
      var self= this;
      let sentence = this.displayTextArray[this.displayTextArrayIndex];
      if (sentence && this.charIndex < sentence.length) {
        if (!this.typeStatus) this.typeStatus = true;
        let c = this.displayTextArray[this.displayTextArrayIndex].charAt(
          this.charIndex
        );
        if (c == '\n')
          this.typeValue += '<br/>'
        else
          this.typeValue += c
        this.charIndex += 1;
        this.timer = setTimeout(this.typeText, this.typingSpeed);
      } else {
        this.$emit('over');
        setTimeout(function(){
          self.typeValue = self.typeValue.replace('Theo', '<h1>Theo');
          }, 200)
        self.typeValue = self.typeValue.replace('Hennessy', 'Hennessy</h1>');
        this.charIndex = 0;
        this.typeStatus = false;
        if (this.displayTextArrayIndex < this.displayTextArray.length)
          this.displayTextArrayIndex++;
        else{
          // self.typeValue = '<h2>theo hennessy</h2>';
          this.$emit('finishedTyping');
          return
        }
        this.timer = setTimeout(this.typeText, this.newTextDelay + 200);
      }
    },
    fade() {
    }
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.container {
  padding:0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.typer {
  font-weight: normal;
  font-family: monospace;
  span.typed-text {
  }
}

// Cursor blinking CSS Starts...
.blinking-cursor {
  color: var(--v-primary-base);
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}
@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}
@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}
@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}
@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}
@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}
// Cursor blinking CSS Ends...
</style>


