import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import WorkView from '../views/WorkView.vue'
import DomainView from '../views/DomainView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/work',
    name: 'work',
    component: WorkView
  },
  {
    path: '/frontend',
    name: 'frontend',
    component: DomainView
  },
  {
    path: '/cartography',
    name: 'cartography',
    component: DomainView
  },
  {
    path: '/backend',
    name: 'backend',
    component: DomainView
  },
  {
    path: '/business-intelligence',
    name: 'business-intelligence',
    component: DomainView
  },
  {
    path: '/machine-learning',
    name: 'machine-learning',
    component: DomainView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
