import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import "leaflet/dist/leaflet.css";
import TheoResponsive from '@/components/TheoResponsive.vue'
import TheoMap from '@/components/TheoMap.vue'
import TheoBackDemo from '@/components/TheoBackDemo.vue'
import TheoBiDemo from '@/components/TheoBiDemo.vue'

export default new Vuex.Store({
  state: {
    drawer: false,
    playAnimations: true,
    domains: {
      "frontend": {
          primColor: "#42B580",
          link: "frontend",
          displaySize: 6,
          code: "frontend",
          src: "vue-badge-outlined.svg",
          slides: [
            { type: 'img', src: '/renders/AHA_mockup.jpg' },
            { type: 'cpt', src: TheoResponsive},
            { type: 'img', src: '/renders/clicstreet_mockup.png' }
          ],
          clientLead: "J'ai déjà utilisé Vue.js pour concevoir et/ou intégrer : ",
          sidebars: [
            {
              icon: 'mdi-cellphone',
              title: '“Mobile first”',
              description: "Parce qu’aujourd’hui près de <strong>60%</strong> du trafic internet se déroule sur mobile, ma démarche se focalise sur l’expression des informations essentielles.",
            },
            {
              icon: 'mdi-television',
              title: '“TV-friendly”',
              description: "Avec un enrichissement progressif des contenus pour des écrans plus larges."
            },
          ],
          clients: [
            {
              name: 'Un portail de paiement sécurisé pour monnaie dématérialisée',
              employer: 'alchemist_coop',
            },
            {
              name: "Une interface de paiement et d'abonnement pour une webapp",
              employer: 'iprotego',
            },
            {
              name: 'Un dashboard de monitoring des métriques du site',
              employer: 'alchemist_coop',
            },
            {
              name: "Un site web pour un artiste peintre",
              employer: 'niall',
            },
            {
              name: "Un backoffice de gestion d'une boutique en ligne",
              employer: 'alchemist_coop',
            },
            {
              name: "Un site web pour un cabinet d'architectes",
              employer: 'cdp',
            },
            {
              name: "Une interface pour jouer des partitions au piano",
              employer: 'th',
            },
            {
              name: "Un site web pour un cabinet d'architectes",
              employer: 'aha',
            },
            {
              name: "Un backoffice de déploiement de microservices",
              employer: 'cogepart',
            },
            {
              name: "Une application cartographique de gestion de flotte",
              employer: 'cogepart',
            },
          ],
        },
      "backend":  {
          primColor: "#0C4B33",
          link: "backend",
          code: "backend",
          displaySize: 6,
          src: "django-python-logo.png",
          slides: [
            { type: 'img', src: '/renders/django_code_mockup.jpg' },
            { type: 'cpt', src: TheoBackDemo},
            { type: 'img', src: '/renders/django_mockup.png' },
          ],
          clientLead: "J'ai déjà utilisé Django pour concevoir et/ou intégrer : ",
          sidebars: [
            {
              icon: 'mdi-server',
              title: '“Robust architecture”',
              description: 'Concevoir des systèmes backend performants et évolutifs pour des applications à grande échelle.',
            },
            {
              icon: 'mdi-monitor',
              title: '“Scalable solutions”',
              description: 'Créer des architectures flexibles adaptées aux besoins actuels et futurs.'
            }
          ],
          clients: [
            {
              name: "Une API au service de plusieurs WebApps et applis Mobiles",
              employer: 'iprotego',
            },
            {
              name: "La gestion des stocks pour une boutique dématérialisée",
              employer: 'alchemist_coop',
            },
            {
              name: "Une page de monitoring de l'offre de Vélo électriques à Marseille",
              employer: 'lezero',
            },
            {
              name: "Un microservice ML d'optimisation de parcours pour la logistique",
              employer: 'cogepart',
            },
            {
              name: "Un flux de paiement/abonnement complet intégré à Stripe",
              employer: 'iprotego',
            },
            {
              name: "Une API interfaçant avec du MIDI pour contrôler un piano",
              employer: 'th',
            },
          ],
        },
      "machine-learning": {
          link: "machine-learning",
          code: "ml",
          displaySize: 6,
          primColor: "#D55B53",
          src: "ml.jpeg",
          slides: [
            { type: 'img', src: '/renders/cogepart_mockup.jpg' },
            { type: 'cpt', src: TheoBackDemo},
            { type: 'img', src: '/renders/ml_frameworks.png' },
          ],
          clientLead: "J'ai fait du Machine-Learning sur les projets suivants : ",
          clients: []
        },
      "cartography":  {
          link: "cartography",
          code: "cartography",
          primColor: "#5178F2",
          displaySize: 6,
          src: "map.png",
          slides: [
            { type: 'img', src: '/renders/LeZero_mockup.jpg' },
            { type: 'cpt', src: TheoMap},
            { type: 'img', src: '/renders/clicstreet_mockup.png' }
          ],
          clientLead: "J'ai déjà utilisé Leaflet.js pour concevoir et/ou intégrer : ",
          clients: [
            {
              name: "Une page de monitoring de l'offre de Vélo électriques à Marseille",
              employer: 'lezero',
            },
            {
              name: "Une marketplace carto pour les commerçants de Grenoble",
              employer: 'clicstreet',
            },
            {
              name: "Une webapp pour générer des parcours dans les Calanques",
              employer: 'th',
            },
            {
              name: "Des optimisations de parcours pour la livraison à domicile",
              employer: 'cogepart',
            },
            {
              name: "Une application cartographique de gestion de flotte",
              employer: 'cogepart',
            },
          ],
        },
      "business-intelligence":  {
          primColor: "#06C2D3",
          link: "business-intelligence",
          displaySize: 6,
          code: "bi",
          src: "dash.jpeg",
          slides: [
            { type: 'cpt', src: TheoBiDemo},
          ],
        },
    }
  },
  getters: {
  },
  mutations: {
    setDrawer(state, val) {
      state.drawer = val;
    },
    setPlayAnimations(state, val) {
      state.playAnimations = val;
    }
  },
  actions: {
  },
  modules: {
  }
})
