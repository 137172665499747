<template>
    <span>
        <theo-skills
        :textArray='["theo has worked with:"]'
        ></theo-skills>
    </span>
  </template>
  
  <script>
  import TheoSkills from '@/components/TheoSkills.vue';
  
    export default {
      name: 'AboutView',
      data: function() {
        return {}
      },  
      components: {
        TheoSkills,
      },
    }
  </script>
  