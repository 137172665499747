<template>
  <v-container class="theo-map">
    <l-map class="map" style="" :zoom="map.zoom" :options="map.options" ref="map" :center="map.center">
      <l-tile-layer :options="map.options" :url="map.url"></l-tile-layer>
    </l-map>
  </v-container>
</template>

<script>
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import {LMap, LTileLayer} from 'vue2-leaflet';
  export default {
    name: 'TheoMap',
    components: {
      LMap,
      LTileLayer,
    },
    methods: {
      deleteMe(idx) {
        this.colors.splice(idx, 1)
      }
    },
    mounted() {
      // this.$refs.map.mapObject.fitWorld();
    },
    data() {
      return {
        colors: ['blue', 'light-blue', 'green','light-green', 'orange', 'deep-orange'],
        map: {
          url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYm9id2F0Y2hlcngiLCJhIjoiY2xiMGwwZThrMWg3aTNwcW1mOGRucHh6bSJ9.kNHlmRqkRSxYNeipcKkJhw   ',

          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          zoom: 10,
          options: {
            zoomOffset: -1,
            tileSize: 512
          },
          center: [43.293419558766885, 5.378547043196854],
        }
      }
    }
  }
</script>

<style lang="scss">
.theo-map {
  height: 400px;
  width: 100%;
  position: relative;
  .leaflet-control-attribution {
    display: none;
  }
  .map {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 1;
    width: 100%;
  }
}
</style>