<template>
  <span>
    <theo-skills
      :textArray="$t('theo_builds')"
    ></theo-skills>
  </span>
</template>

<script>
import TheoSkills from '@/components/TheoSkills.vue';

  export default {
    name: 'AboutView',
    data: function() {
      return {}
    },  
    components: {
      TheoSkills,
    },
  }
</script>
