<template>
  <v-container :style="cssVars" class="domain-page" fluid>
    <v-row>
      <v-col cols="12" md="6" offset-md="3" class="page mt-6">
        <div class="d-flex align-center">
          <h1 class="pl-2 mb-0">{{ copy.title }}</h1>
        </div>
        <theo-caroussel
          :slides="domain.slides"
        ></theo-caroussel>
        <h3>
          <i18n :path="copy.lead.text" tag="p"  v-if="copy.lead">
            <template v-slot:url>
              <a href="https://www.vuejs.org" class="domain-link" target="_blank">{{ $t(i18npath + '.lead.link') }}</a>
            </template>
          </i18n>
        </h3>
        <v-row>
          <v-col cols="6" md="4">
            <TheoSidebar
              :domain="domain"
              :sidebars="[]"></TheoSidebar>
          </v-col>
          <v-col cols="6" md="8" class="domain-copy">
            <i18n :path="copy.para1.text" tag="p" v-if="copy.para1">
              <template v-slot:url1>
                <a href="https://www.vuejs.org" class="domain-link" target="_blank">{{ $t(i18npath + '.para1.link1') }}</a>
              </template>
              <template v-slot:url2>
                <a href="https://www.vuejs.org" class="domain-link" target="_blank">{{ $t(i18npath + '.para1.link2') }}</a>
              </template>
            </i18n>
            <i18n :path="copy.para2.text" tag="p" v-if="copy.para2">
              <template v-slot:strong1>
                <strong>{{ $t(i18npath + '.para2.strongText') }}</strong>
              </template>
              <template v-slot:url1>
                <a href="https://www.vuejs.org" class="domain-link" target="_blank">{{ $t(i18npath + '.para2.link1') }}</a>
              </template>
              <template v-slot:url2>
                <a href="https://www.vuejs.org" class="domain-link" target="_blank">{{ $t(i18npath + '.para2.link2') }}</a>
              </template>
            </i18n>
            <TheoClients
              :clients="domain.clients"
              :lead="domain.clientLead"
            ></TheoClients>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import TheoCaroussel from '@/components/TheoCaroussel.vue';
import TheoClients from '@/components/TheoClients.vue';
import TheoSidebar from '@/components/TheoSidebar.vue';

export default {
  name: 'DomainView',
  data() {
    return {
      i18npath: null,
      copy: {},
    }
  },
  components: {TheoCaroussel, TheoClients, TheoSidebar},
  mounted() {
    this.buildCopy();
    // this.playInterval = setInterval(this.next, this.playSpeed);
  },
  computed: {
    domain() {
      return this.$store.state.domains[this.$route.name];
    },
    cssVars() {
      return {
        "--domain-color": this.$vuetify.theme.themes['light'][this.domain.code + '_color']
      }
    }
  },
  methods: {
    buildCopy() {
      this.i18npath = 'domains.'  + this.$route.name;
      this.copy = {
        title: this.$t(this.i18npath + '.subtitle'),
        lead: {
          link: this.i18npath + '.lead.link',
          text: this.i18npath + '.lead.text'
        },
        para1: {
          link2: this.i18npath + '.para1.link2',
          link1: this.i18npath + '.para1.link1',
          text: this.i18npath + '.para1.text'
        },
        para2: {
          link2: this.i18npath + '.para2.link2',
          link1: this.i18npath + '.para2.link1',
          text: this.i18npath + '.para2.text'
        }
      }
    }
  },
  watch: {
    domain() {
      this.buildCopy();
    }
  }
}
</script>
<style lang="scss">
  .clients .v-item--active .v-expansion-panel-header{
    // transition: all 0.5s ease;
    color: var(--domain-color);
    font-size: 1em;
    font-weight: bold;
  }
  .domain-page {
    height: 100%;
    background: white;
    h1 {
      color:  #fff;
      background: var(--domain-color);
      display: block;
      width: 100%;
      font-size: 1.6em;
    }
    h3 {
      margin-bottom: 2rem;
    }
    a.domain-link {
      text-decoration: none;
      font-weight: bold;
      color: var(--domain-color);
    }
    .v-chip {
      color: black;
      background: rgba(255,255,255,0.1);
    }
    .domain-card {
      background: var(--domain-color);

    }
    p {
      text-indent: 4em;
      text-align: justify;
    }
  }
</style>
