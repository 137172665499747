<template>
  <v-container>
    <!-- Dynamic image based on the domain -->
    <v-img :src="'/' + domain.src" height="192" contain class="ma-6 mt-0" />

    <v-card dark class="domain-card pa-4">
      <!-- Dynamic title and description -->
       <span v-for="(bar, idx) in domain.sidebars" :key="idx">
         <h2 class="text-center">
           <v-icon x-large>{{ bar.icon }}</v-icon><br/>{{ bar.title }}
         </h2>
         <span class="text-justify" v-html="bar.description"></span>
       </span>

    </v-card>
  </v-container>
</template>

<script>
export default {
    name: 'TheoSidebar',
    props: {
      domain: { type: Object, default() {
        return {
          src: {},
          sidebars: [],
        };
      }},
    },
}
</script>


<style lang="scss" scoped>
  .clients .v-item--active .v-expansion-panel-header{
    // transition: all 0.5s ease;
    color: var(--domain-color);
    font-size: 1em;
    font-weight: bold;
  }
  .frontend {
    height: 100%;
    background: white;
    h1 {
      color:  #fff;
      background: var(--domain-color);
      display: block;
      width: 100%;
    }
    h3 {
      margin-bottom: 2rem;
    }
    a {
      font-weight: bold;
      color: var(--domain-color);
    }
    .v-chip {
      color: black;
      background: rgba(255,255,255,0.1);
    }
    .domain-card {
      background: var(--domain-color);

    }
    .icoded {
      color: white;
      background: var(--domain-color);
    }
  }
</style>
