<template>
    <v-container class="responsive d-flex theo-backdemo justify-center">
    </v-container>
  </template>
  
  <script>
    export default {
      name: 'TheoBackDemo',
      methods: {
        deleteMe(idx) {
          this.colors.splice(idx, 1)
        }
      },
      data() {
        return {
          colors: ['blue', 'light-blue', 'green','light-green', 'orange', 'deep-orange']
        }
      }
    }
  </script>
  
  <style lang="scss" scoped>
  .theo-backdemo {
      height: 400px;
  }
  </style>