<template>
  <v-container class="skills" ref="skills" fluid centered >
    <v-row class="pa-0 my-0 mt-md-6">
      <v-col cols="12" md="6" offset-md="3">
        <!-- <h1 class="text-center primary--text">i love designing</h1> -->
        <h1 class="d-none">
          <TheoTyperBlue
            class="typer"
            :display-text-array="textArray"
            ref="typer"
            
          ></TheoTyperBlue>
        </h1>
      </v-col>
    </v-row>
    <v-row class="pa-0 mt-0 mt-md-6">
      <v-col cols="12" md="8" offset-md="2">
        <v-row>
          <v-col v-for="(domain, idx) in domains" :key="idx" cols="10" offset="1" offset-md="0" :md="domain.displaySize" class="py-2 py-md-4">
            <v-slide-x-transition>
              <v-card
                :to="domain.link"
                v-if="(expand(idx) || !playAnimations)"
                elevation="0"
                :style="colorMe(domain.primColor)"
                class="d-flex white--text align-center px-2 py-md-2">
                <span>
                  <v-img class="icon" :src="domain.src"></v-img>
                </span>
                <span class="copy">
                  <span href=""><b>{{ $t('domains.'+domain.link+'.subtitle') }}<br/></b></span>
                  <span class="d-none d-md-inline">
                    {{ $t('domains.'+domain.link+'.description') }}
                  </span>
                </span>
              </v-card>
            </v-slide-x-transition>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TheoTyperBlue from './TheoTyperBlue.vue';


  export default {
    name: 'TheoSkills',
    methods: {
      colorMe(color) {
        return {"background": color};
      },
      setColor(color) {
        this.$refs["skills"].style.background = color;
      },
      setExpander() {
        if (this.expandIndex < this.domains.length)
          this.expandIndex++;
        else
          this.playAnimations = false;
      },
      expand(idx) {
        return idx < this.expandIndex;
      }
    },
    mounted(){
      var vm = this;
      setTimeout(function() {
        vm.expandInterval = setInterval(vm.setExpander, 500)
      }, 1000);
    },
    computed: {
      domains() {
        return Object.values(this.$store.state.domains);
      },
      playAnimations: {
        get() { return this.$store.state.playAnimations },
        set(val) { this.$store.commit('setPlayAnimations', val); }
      }
    },
    props: ["textArray"],
    data: function() {
      return {
        expandInterval: null,
        expandIndex: 0,
      }
    },
    components: { TheoTyperBlue }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
.skills {
  .typer {
    font-size: 1.6rem;
    text-align: center;
    color: var(--v-primary-base)
  }
  background: transparent;
  transition: background 1s ease;
  color:  var(--v-primary-base);
  height: 100%;
  padding: 0;
  color: white;
  .icon {
    font-size: 2rem;
    position: relative;
    left: -4px;
    width: 3rem;
    height:3rem;
    border: 0px solid var(--v-primary-lighten1);
    border-radius: 50%;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: 5rem;
      height:5rem;
      left: 8px;
    }
  }
  .copy {
    padding: 1rem;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: 1.2rem;
    }
  }
  
}
</style>