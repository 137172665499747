<template>
  <span>
    <theo-intro />
    <!-- <theo-transition></theo-transition>
    <theo-skills id="sec-3"></theo-skills> -->
    <!-- <TheoTyper :display-text-array="textArray"></TheoTyper> -->
  </span>
</template>

<script>
  // import TheoTyper from '@/components/TheoTyper.vue';
import TheoIntro from '../components/TheoIntro'
// import TheoSkills from '@/components/TheoSkills.vue';
//   import TheoTransition from '@/components/TheoTransition.vue';

  export default {
    name: 'HomeView',
    data: function() {
      return {}
    },  
    components: {
      TheoIntro,
      // TheoTyper,
      // TheoSkills,
      // TheoTransition,
    },
  }
</script>
