<template>
    <v-container class="responsive d-flex  justify-center">
      <v-card flat class="rounded-lg chip-container pb-0 d-flex flex-wrap justify-center " color="white">
        <v-card 
            flat dark class="rounded-lg chip my-4 mx-2" :color="colors[0]">
            <h5>clicks</h5>
            <br/>
            {{  clickCount  }}
        </v-card>
        <v-card 
            flat dark class="rounded-lg chip my-4 mx-2  cux-2" :color="colors[1]">
        </v-card>
        <v-card 
            flat dark class="rounded-lg chip my-4 mx-2 cuy-2" :color="colors[5]">
        </v-card>
        <v-card 
            flat dark class="rounded-lg chip my-4 mx-2" :color="colors[2]">
            <v-btn 
                flat outlined
                class="rounded-xl clicker px-0 py-4" color="white"
                @click="incr"
            >
                click
            </v-btn>
        </v-card>
        <v-card 
            flat dark class="rounded-lg chip my-4 mx-2" :color="colors[3]">
        </v-card>
        <!-- <v-card 
            flat dark class="rounded-lg chip my-4 mx-2" :color="colors[4]">
        </v-card> -->
      </v-card>
    </v-container>
  </template>
  
  <script>

    export default {
      name: 'TheoResponsive',
      data() {
        return {
            clickCount: 0,
            colors: ['blue', 'light-blue', 'green','light-green', 'orange', 'deep-orange']
        }
      },
      methods: {
        incr() {
            this.clickCount++;
        }
      }
    }
  </script>
  
  <style lang="scss" scoped>
  .chip {
    height: 4rem;
    font-size: 16px;
    width: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.cux-2 {
        width: 9rem;
    }
    &.cuy-2 {
        height: 9rem;
    }
  }
  .clicker {
    position: relative;
    border-width: 0;
    font-size: 10px;
    padding: 0 !important;
    min-width: 0 !important;
    width: 3rem !important;
    height: 3rem !important;
  }
  .chip-container {
    display: flex;
    border: 16px solid #f2f2f2 !important;
    font-size: 64px;
    height: 5em;
    width: 5em;
  }
  .responsive {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 400px;
  }
  </style>