<template>
    <v-navigation-drawer
    v-model="drawer"
    absolute
    left
    temporary
    >
    <v-list
        nav
        dense
    >
        <v-list-item-group
        v-model="group"
        active-class="primary--text text--accent-4"
        >
            <v-list-item class="d-flex align-bas">
                <span class="mini-logo">th:</span>
            </v-list-item>
            <v-list-item to="/">
                {{ $t('ABOUT') }}
            </v-list-item>
            <v-list-item>
                <v-list-item-group
                v-model="group"
                class="domain-group"
                active-class="primary--text text--accent-4"
                >
                <router-link 
                  class="nav-link"
                  to="/about">
                  {{ $t('SOFTWARE DEVELOPMENT') }}
                </router-link>
                <v-list-item 
                    v-for="(dom, idx) in domains"
                    :key="idx"
                    :to="'/'+dom.link"
                    :class="dom.link"
                >
                    <v-list-item-title>{{ $t('domains.'+dom.link+'.title')}}</v-list-item-title>
                </v-list-item>
                </v-list-item-group>
            </v-list-item>
            <v-list-item>
                PAST PROJECTS
            </v-list-item>
        </v-list-item-group>
    </v-list>
    </v-navigation-drawer>
  </template>
  
  <script>
  export default {
  
    name: 'TheoNav',
  
    data: () => ({
        group: null,
    }),
    computed: {
        drawer:{
            get() { return this.$store.state.drawer; },
            set(val) { this.$store.commit('setDrawer', val); }
        },
        domains() { return this.$store.state.domains}
    },
    watch: {
      group() {
        this.drawer = false;
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  a.nav-link {
    text-decoration: none;
     color: unset;
  }
  .mini-logo {
    position: relative;
    top: -2px;
    font-family: monospace;
    font-weight: bold;
    color: var(--v-primary-base);
  }
  .domain-group {
    margin: 0;
    .frontend {
      border-left: 6px solid green;
    }
  }
  </style>