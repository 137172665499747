<template>
  <v-container ref="hello" class="hello" fluid :class="{complete: finishedTyping}">
    <v-row>
      <!-- <v-col cols="3" md="1" offset-md="2"> -->
      <!-- </v-col> -->
      <v-col cols="12" md="6" offset-md="3" class="bop">
        <p @click="handleFinished()" class="prevent-select">
          <!-- <v-img v-show="finishedTyping"  class="photo" src="theo.jpg"/> -->
          <TheoTyper
            :display-text-array="textArray"
            ref="typer"
            speed="100"
            
          ></TheoTyper>
          <!-- <v-btn v-show="finishedTyping" color="white" outlined>ABOUT</v-btn> -->
        </p>
        <!-- <p>hello my name is <b>theo hennessy</b></p>
        <p>i am a <b>senior software developer</b> with extensive experience in conceiving</p> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TheoTyper from './TheoTyper.vue';

TheoTyper
  export default {
    name: 'TheoIntro',
    data: function() {
      return {
        textArray: this.$t('greetArray'),
        finishedTyping: false,
        fade: false,
        tapper: 0,
      }
    },  
    components: { TheoTyper },
    methods: {
      scrollTo: function(){
      },
      handleFinished: function() {
        this.tapper++;
        var self = this;
        // this.$refs['typer'].fade();
        if(this.tapper > 4){
          this.finishedTyping = true;
          setTimeout(function() {self.$router.push('/about')}, 2000);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
.hello {
  padding: 0;
  margin: 0;
  position: relative;
  left:0;
  transition: all 2s ease-in-out;
  background: var(--v-primary-base);
  text-align: center;
  height: 105%;
  font-size: 1rem;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: 1.5rem;
  }
  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  &.complete {
    height: 0;
    p {
      opacity: 0;
    }
  }
  color: white;
  p {
    // border: 1px solid white;
    // background: white;
    // color: var(--v-primary-base);
    position: relative;
    left: 0;
    margin: 0;
    padding: 0;
    transition: all 1s ease-in-out;
    color: white;
    margin: 1.5rem 0;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
  }
  .photo {
    border: .25rem solid var(--v-primary-base);
    margin: 0 auto;
    width:5em;
    border-radius: 50%;
  }
}
</style>