import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
            primary: '#1976D2',
            secondary: '#82B1FF',
            accent: '#82B1FF',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
            frontend_color: '#42B580',
            cartography_color: '#5178F2',
            backend_color: '#0C4B33',
            bi_color: "#06C2D3",
            ml_color: "#D55B53"
          },
      },
      options: {
        customProperties: true,
      },
    },
  })
  
  export default vuetify